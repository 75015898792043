import React, { useState } from "react";
import * as jobStyles from "../../styles/components/job.module.scss";
import Layout from "../../components/layout";
import { graphql, Link, navigate } from "gatsby";
import { SEO } from "../../components/seo";
import parse from "html-react-parser";
import * as accents from "remove-accents";
import { getPageByLanguage } from "../../hooks/get-page-by-language";
import useCookie from "../../hooks/use-cookie";
import { getBrowserLanguage } from "../../hooks/get-language";
import { getHeaderAndFooter } from "../../hooks/get-header-and-footer";

const Job = ({ data, location }) => {
  const [dropdown, setDropdown] = useState({
    key: "",
    name: "",
  });
  const [cookie, setCookie] = useCookie(
    "language",
    getBrowserLanguage(location)
  );

  const { foundHeader, foundFooter } = getHeaderAndFooter(data, cookie);
  let findJobPosting = data.allContentstackJobPostings.nodes.filter((job) => {
    let findUrl = job.title
      .split(" ")
      .map((el) => {
        let lowercaseTitle = el.toLowerCase();
        let removeAccentsTitle = accents.remove(lowercaseTitle);
        return removeAccentsTitle.replace(/[^a-zA-Z0-9-_]/g, "");
      })
      .join("");
    return location.pathname === `/careers/${findUrl}`;
  });
  let foundJobPostingByLocale = getPageByLanguage(
    findJobPosting,
    false,
    cookie
  );
  let foundJobPostingDuplicateContent = getPageByLanguage(
    data.allContentstackJobPostingDuplicateContent.nodes,
    false,
    cookie
  );

  const isBrowser = typeof window !== "undefined";
  if (!foundJobPostingByLocale && isBrowser) {
    navigate("/404/");
  }

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let date = new Date(foundJobPostingByLocale?.updated_at);
  let getDayYear = date.toDateString().split(" ").splice(2);
  let formattedDate = [
    monthNames[date.getMonth()],
    `${getDayYear[0]},`,
    getDayYear[1],
  ].join(" ");
  let locationList = foundJobPostingByLocale?.locations.map((el, idx) => {
    if (idx + 1 === foundJobPostingByLocale.locations.length) {
      return el.location_list;
    } else {
      return el.location_list + "; ";
    }
  });
  return (
    <Layout
      navigation={foundHeader}
      footer={foundFooter}
      dropdown={dropdown}
      setDropdown={setDropdown}
      cookie={cookie}
      setCookie={setCookie}
    >
      <section className={jobStyles.jobSection}>
        <h1 className={jobStyles.mainTitle}>
          {foundJobPostingByLocale?.title}
        </h1>
        <div className={jobStyles.descContent}>
          <p>
            {foundJobPostingDuplicateContent?.location_label}: {locationList}
          </p>
          <p>
            {foundJobPostingDuplicateContent?.department_label}:{" "}
            {foundJobPostingByLocale?.department.department_list}
          </p>
          <p>
            {foundJobPostingDuplicateContent?.date_label}: {formattedDate}
          </p>
        </div>
        <div
          className={
            foundJobPostingByLocale?.job_posting_url
              ? jobStyles.contentWithBtn
              : jobStyles.contentWithoutBtn
          }
        >
          <h3 className={jobStyles.contentTitle}>
            {foundJobPostingDuplicateContent.title}
          </h3>
          <p className="p-medium">
            {parse(foundJobPostingDuplicateContent.main_content)}
          </p>
          {foundJobPostingByLocale?.position_overview_content && (
            <div className={jobStyles.contentSection}>
              <h3 className={jobStyles.contentTitle}>
                {foundJobPostingDuplicateContent?.position_overview_label}
              </h3>
              <p className="p-medium">
                {parse(foundJobPostingByLocale?.position_overview_content)}
              </p>
            </div>
          )}
          {foundJobPostingByLocale?.responsibilities_content && (
            <div className={jobStyles.contentSection}>
              <h3 className={jobStyles.contentTitle}>
                {foundJobPostingDuplicateContent?.responsibilities_label}
              </h3>
              <p className="p-medium">
                {parse(foundJobPostingByLocale?.responsibilities_content)}
              </p>
            </div>
          )}
          {foundJobPostingByLocale?.minimum_qualifications_content && (
            <div className={jobStyles.contentSection}>
              <h3 className={jobStyles.contentTitle}>
                {foundJobPostingDuplicateContent?.minimum_qualifications_label}
              </h3>
              <p className="p-medium">
                {parse(foundJobPostingByLocale?.minimum_qualifications_content)}
              </p>
            </div>
          )}
          {foundJobPostingByLocale?.preferred_qualifications_content && (
            <div className={jobStyles.contentSection}>
              <h3 className={jobStyles.contentTitle}>
                {
                  foundJobPostingDuplicateContent?.preferred_qualifications_label
                }
              </h3>
              <p className="p-medium">
                {parse(
                  foundJobPostingByLocale?.preferred_qualifications_content
                )}
              </p>
            </div>
          )}
          {foundJobPostingByLocale?.physical_requirements_content && (
            <div className={jobStyles.contentSection}>
              <h3 className={jobStyles.contentTitle}>
                {foundJobPostingDuplicateContent?.physical_requirements_label}
              </h3>
              <p className="p-medium">
                {parse(foundJobPostingByLocale?.physical_requirements_content)}
              </p>
            </div>
          )}
          {foundJobPostingByLocale?.salary_content && (
            <div className={jobStyles.contentSection}>
              <h3 className={jobStyles.contentTitle}>
                {foundJobPostingDuplicateContent?.salary_label}
              </h3>
              <p className="p-medium">
                {parse(foundJobPostingByLocale?.salary_content)}
              </p>
            </div>
          )}
        </div>
        {foundJobPostingByLocale?.job_posting_url && (
          <a
            href={foundJobPostingByLocale?.job_posting_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="button-small btn-light">
              {foundJobPostingDuplicateContent?.apply_link_text}
            </button>
          </a>
        )}
        {foundJobPostingByLocale?.find_job_instructions && (
          <div className={jobStyles.contentSection}>
            <h3 className={jobStyles.contentTitle}>
              {foundJobPostingDuplicateContent?.how_to_apply_label}
            </h3>
            <p className="p-medium">
              {foundJobPostingByLocale?.find_job_instructions}
            </p>
          </div>
        )}
        <Link to="/careers">
          <a className={jobStyles.backLink}>
            {foundJobPostingDuplicateContent?.back_link_text}
          </a>
        </Link>
        <p className={jobStyles.disclaimerContent}>
          {parse(foundJobPostingDuplicateContent.bottom_content)}
        </p>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    allContentstackHeader {
      nodes {
        locale
        menu {
          link {
            href
            title
          }
        }
        logo {
          logo_title
          logo_image {
            url
          }
        }
      }
    }
    allContentstackFooter {
      nodes {
        locale
        address {
          street
          city_state_zip
          phone_number
          email
        }
        company_motto {
          motto_line_1
          motto_line_2
        }
        copyright
        locale
        title
        uid
        secondary_menu {
          link {
            href
            title
          }
        }
        main_links {
          link {
            href
            title
          }
        }
        logo {
          logo_image {
            url
          }
          logo_title
        }
      }
    }
    allContentstackJobPostings {
      nodes {
        locale
        title
        job_posting_url
        find_job_instructions
        updated_at
        responsibilities_content
        preferred_qualifications_content
        position_overview_content
        physical_requirements_content
        minimum_qualifications_content
        salary_content
        locations {
          location_list
        }
        department {
          department_list
        }
      }
    }
    allContentstackJobPostingDuplicateContent {
      nodes {
        locale
        title
        main_content
        bottom_content
        location_label
        department_label
        date_label
        position_overview_label
        responsibilities_label
        minimum_qualifications_label
        preferred_qualifications_label
        physical_requirements_label
        salary_label
        apply_link_text
        how_to_apply_label
        back_link_text
      }
    }
  }
`;
export default Job;

export const Head = () => <SEO title="Elevate Outdoor Collective | Careers" />;
