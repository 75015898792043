// extracted by mini-css-extract-plugin
export var backLink = "job-module--back-link--21d83";
export var btnDark = "job-module--btn-dark--6d098";
export var btnLight = "job-module--btn-light--03929";
export var buttonDefault = "job-module--button-default--53bab";
export var buttonLarge = "job-module--button-large--f2b63";
export var buttonSmall = "job-module--button-small--8388c";
export var contentSection = "job-module--content-section--e3499";
export var contentTitle = "job-module--content-title--bf1b4";
export var contentWithBtn = "job-module--content-with-btn--43a60";
export var contentWithoutBtn = "job-module--content-without-btn--198c2";
export var ctaLarge = "job-module--cta-large--3d83b";
export var ctaSmall = "job-module--cta-small--e61c9";
export var descContent = "job-module--desc-content--323a1";
export var disclaimerContent = "job-module--disclaimer-content--8258e";
export var highlight = "job-module--highlight--81bac";
export var input = "job-module--input--a1d1c";
export var jobSection = "job-module--job-section--f95e2";
export var label = "job-module--label--4f5f1";
export var labelLarge = "job-module--label-large--1125d";
export var labelMedium = "job-module--label-medium--0050a";
export var languages = "job-module--languages--6fb4f";
export var link = "job-module--link--bc72b";
export var mainTitle = "job-module--main-title--56113";
export var optionContainer = "job-module--optionContainer--6ed95";
export var optionListContainer = "job-module--optionListContainer--e2d4a";
export var pLarge = "job-module--p-large--58c9a";
export var pLargeBold = "job-module--p-large-bold--22088";
export var pMedium = "job-module--p-medium--9a626";
export var pSmall = "job-module--p-small--e4ccf";
export var searchWrapper = "job-module--search-wrapper--c15ab";
export var singleChip = "job-module--singleChip--ae920";
export var slickNext = "job-module--slick-next--ed25d";
export var slickPrev = "job-module--slick-prev--b462f";
export var slickSlide = "job-module--slick-slide--89be5";
export var titleLarge = "job-module--title-large--5654a";
export var titleMedium = "job-module--title-medium--21b9f";
export var titleSmall = "job-module--title-small--efeb4";
export var titleXl = "job-module--title-xl--bf81e";